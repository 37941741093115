export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Play', sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Play', sans-serif",
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Play', sans-serif",
  },
  h4: {
    fontSize: "18px",
    color: "#FFFFFF",
    letterSpacing: "1px",
    fontWeight: "600",
    marginBottom: "16px",
    fontFamily: "'Play', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Play', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "'Roboto', sans-serif",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Play', sans-serif",
  },
  body1: {
    fontSize: 16,
    color: "#9F9F9F",
    lineHeight: "28px",
    fontWeight: "400",
    fontFamily: "'Play', sans-serif",
  },
};
