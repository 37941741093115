import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK } from "src/constants";
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
  calculateTimeLeft,
} from "src/utils";
import { CryptochipsContract } from "src/constants";
import CryptoChipsABI from "src/ABI/CryptoChipsABI.json";
import axios from "axios";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";
import moment from "moment";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, library, deactivate, chainId } = useWeb3React();
  const [nftPrice, setNftPrice] = useState(0);
  const [hasFinalSaleStarted, setHasFinalSaleStarted] = useState(false);
  // const [reservedClaimed, setReservedClaimed] = useState(1);
  // const [RESERVED_NFT, setRESERVED_NFT] = useState(1);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [MAX_MINT, setMAX_MINT] = useState(100);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [publicSaleStartTimestamp, setPublicSaleStartTimestamp] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [allNftList, setallNftList] = useState([]);
  const [userNFTList, setUserNFTList] = useState([]);
  const [mintPercentage, setMintPercentage] = useState(0);
  const [isLoadingAllNFT, setIsLoadingAllNFT] = useState(false);
  const [saleIsActive, setsaleIsActive] = useState(false);
  const [openTimeLeft, setOpenTimeLeft] = useState("");
  useEffect(() => {
    if (
      openTimeLeft &&
      publicSaleStartTimestamp &&
      publicSaleStartTimestamp == moment().unix()
    ) {
      window.location.reload();
    }
  }, [openTimeLeft]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (publicSaleStartTimestamp) {
        setOpenTimeLeft(
          calculateTimeLeft(new Date(parseInt(publicSaleStartTimestamp) * 1000))
        );
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  let data = {
    nftPrice,
    saleIsActive,
    allNftList,
    balanceOfValue,
    adminWalletAddress,
    isLoadingAllNFT,
    // reservedClaimed,
    // RESERVED_NFT,
    MAX_NFT_SUPPLY,
    totalSupply,
    MAX_MINT,
    MAX_NFT_WALLET,
    hasFinalSaleStarted,
    publicSaleStartTimestamp,
    userNFTList,
    mintPercentage,
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectWalletHandler(data),
  };

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(
      CryptochipsContract,
      CryptoChipsABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account]); //eslint-disable-line

  const allNFTListHandler = async (txaCount) => {
    //eslint-disable-line no-unused-vars
    setIsLoadingAllNFT(true);
    const contract = await getWeb3ContractObject(
      CryptoChipsABI,
      CryptochipsContract
    );
    try {
      for (let i = 1; i <= parseInt(txaCount); i++) {
        const tokenURI = await contract.methods.tokenURI(i.toString()).call();
        const res = await axios.get(tokenURI);
        if (res.status === 200) {
          setallNftList((prev) => [
            ...prev,
            { id: i.toString(), nfdData: res.data },
          ]);
        }
      }
      setIsLoadingAllNFT(false);
    } catch (error) {
      setIsLoadingAllNFT(false);

      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (totalSupply > 0) {
      // allNFTListHandler(totalSupply);
    }
  }, [totalSupply]); //eslint-disable-line

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        CryptoChipsABI,
        CryptochipsContract
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      const hasFinalSaleStarted = await contractObj.methods
        .hasFinalSaleStarted()
        .call();
      setHasFinalSaleStarted(hasFinalSaleStarted);
      const publicSaleStartTimestamp = await contractObj.methods
        .publicSaleStartTimestamp()
        .call();

      setPublicSaleStartTimestamp(Number(publicSaleStartTimestamp));

      const saleIsActive = await contractObj.methods.saleIsActive().call();
      setsaleIsActive(saleIsActive);

      // const MAX_MINT = await contractObj.methods.MAX_MINT().call();
      // setMAX_MINT(Number(MAX_MINT));

      if (hasFinalSaleStarted) {
        const NFT_PRICE = await contractObj.methods.NFT_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);

        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      } else {
        const RESERVE_PRICE = await contractObj.methods
          .NFT_PRICE_PRESALE()
          .call();
        const getRESERVE_PRICE = await web3.utils.fromWei(
          RESERVE_PRICE.toString()
        );
        setNftPrice(getRESERVE_PRICE);
        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET_PRESALE()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(CryptoChipsABI, CryptochipsContract, account)
    );
  }

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      CryptoChipsABI,
      CryptochipsContract
    );
    if (account) {
      getBalanceOfFun();
    }
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));

    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
    let per = (parseFloat(totalSupply) / parseFloat(MAX_NFT_SUPPLY)) * 100;
    setMintPercentage(per);
  };

  useEffect(() => {
    getContractDetails();
    getCurrentMintingDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem("userAddress");
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (!account) {
      deactivate();
    }
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
