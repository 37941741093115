import { RPC_URL } from "src/constants";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
});

export const walletlink = new WalletLinkConnector({
  url: [RPC_URL],
  appName: "web3-react example",
  supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80001],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: RPC_URL,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "/images/walletImages/metamask-fox.svg",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },

  {
    name: "COINBASE",
    data: {
      connector: walletlink,
      name: "Coinbase",
      iconName: "/images/walletImages/coinbase.jpeg",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#E8831D",
    },
  },
  {
    name: "Wallet Connect",
    data: {
      connector: walletconnect,
      name: "Wallet Connect",
      iconName: "/images/walletImages/walletconnect.png",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },
];
