export const NetworkContextName = "Ethereum Mainnet";
export const ACTIVE_NETWORK = 1;
export const CryptochipsContract = "0x633da8fa26CEc8A9a0Cf879446544d89aDDED7e4";

export const RPC_URL =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NetworkDetails = [
  {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum Mainnet",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
